export const environment = {
  production: false,
  api_url: 'https://apx-test-back.ienergy.halliburton.com/APXApi/api',
  common_api_url: 'https://apx-test-back.ienergy.halliburton.com/APXCommonApi/api',
  api_file_url: 'https://apx-test-back.ienergy.halliburton.com/APXFileApi/api',
  api_notification_url: 'https://apx-test-back.ienergy.halliburton.com/APXNotificationApi/api',
  authSettings: {
    authority: 'https://myappstest.halliburton.com/oauth2/ausk4axfvsdNLJ8rU0h7',
    client_id: '0oa177e27wh0HD2fQ0h8',
    redirect_uri: 'https://apx-test-front.ienergy.halliburton.com/authcallback',
    post_logout_redirect_uri: 'https://apx-test-front.ienergy.halliburton.com/postlogout',
  },
};
